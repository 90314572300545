import React, {useState, useEffect, useRef} from 'react';
import { useReactToPrint } from 'react-to-print';
import JsPDF from 'jspdf';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import {SwitchReport} from '../components/reporting/SwitchReports';

export const Reporting = () => {

  const url = "https://toolbelt.ctcgroup.com.au:2908/Reporting/Items/RetailGroups/";
  const [report, setReport] = useState("");
  const [changeSupplier, setChangeSupplier] = useState("");
  const [supplier, setSupplier] = useState("");
  const [changeRetailgroup, setChangeRetailgroup] = useState("");
  const [retailgroup, setRetailgroup] = useState("");
  const [changeStart, setChangeStart] = useState("");
  const [changeEnd, setChangeEnd] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isSupplierReport, setIsSupplierReport] = useState(false);
  const [isCostMarkup, setIsCostMarkup] = useState(false);
  const [isItemReport, setIsItemReport] = useState(false);
  const [isPromoReport, setIsPromoReport] = useState(false);
  const [isPricingReport, setIsPricingReport] = useState(false);
  const [loadReport, setLoadReport] = useState("");
  const [doLoadReport, setDoLoadReport] = useState(false);
  const [items, setItems] = useState();

  const [username, setUsername] = useState('');
  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');

  const [storeid, setStoreid] = useState('');

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'report-data',
  });

  const generatePDF = () => {
    const report = new JsPDF('portrait', 'pt', 'a4');
    report.html(document.querySelector('#theReport')).then(() => {
      report.save(`${loadReport}.pdf`);
    })
  }

  useEffect(() => {
        refreshToken();
  }, []);

  const refreshToken = async () => {
        try {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUsername(decoded.name);
            setStoreid(decoded.storeid);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                window.location.replace("/");
            }
        }
    }

    const getRetailgroup = (supplier) => {
          fetch(url + supplier)
          .then((response) => {
            if(!response.ok) {
              throw new Error(`This is an HTTP error: The status is ${response.status}`);
            }
            return response.json();
          })
          .then((result) => {
            setItems(result);
          })
          .catch((err) => {
            setItems(null);
          })
          .finally(() => {
          });
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setStoreid(decoded.storeid);
            setUsername(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

  const showReport = (e) => {
    e.preventDefault();
    setDoLoadReport(false);
    setLoadReport(report);
    setSupplier(changeSupplier);
    setRetailgroup(changeRetailgroup);
    setStartDate(changeStart);
    setEndDate(changeEnd);
    setDoLoadReport(true);
  }

  return(
    <div className="container-xl">
      <h1 className="inlinetitle">Store Reporting</h1>
      <form id="selectReport" className="w-auto g-3 was-validated" onSubmit={showReport}>
        <div className="row">
          <div className="col-md-3">
            <label className="formLabel">Report</label>
            <select id="reportid" className="form-control" name="reportid" defaultValue="" onChange={(e) => { setReport(e.target.value); if(e.target.value === "SupplierReport") setIsSupplierReport(true); else setIsSupplierReport(false); if(e.target.value === "ItemReport") setIsItemReport(true); else setIsItemReport(false); if(e.target.value === "PromoReport") setIsPromoReport(true); else setIsPromoReport(false);if(e.target.value === "CostMarkupReport") setIsCostMarkup(true); else setIsCostMarkup(false);if(e.target.value === "PricingReport") setIsPricingReport(true); else setIsPricingReport(false);}} required>
              <option value="" disabled>-- Select Report --</option>
              <option value="ReportMarketShare">Market Share</option>
              <option value="Departments">Department Report</option>
              <option value="SupplierReport">Supplier Report</option>
              <option value="HourlyReport">Daily Report By Hour</option>
              <option value="ItemReport">Item Report</option>
              <option value="PricingReport">Pricing Report</option>
              <option value="PromoReport">Promo Report</option>
              <option value="CostMarkupReport">Cost Markup Report</option>
            </select>
          </div>
          {
            isSupplierReport || isItemReport || isPromoReport || isCostMarkup || isPricingReport ? (
              <div className="col-md-3">
                <label className="formLabel">Supplier</label>
                <select id="supplierid" className="form-control" name="supplierid" defaultValue="" onChange={(e) => { setChangeSupplier(e.target.value); if(report === 'ItemReport') getRetailgroup(e.target.value);}} required>
                  <option value="" disabled>-- Select Supplier --</option>
                  <option value="BATA">British American Tobacco</option>
                  <option value="PML">Phillip Morris</option>
                  <option value="ITA">Imperial Brands</option>
                  <option value="RICH">Richland Brands</option>
                  <option value="OTHER">Other</option>
                </select>
              </div>
            ) : ( "" )
          }
          {
            isItemReport && changeSupplier ? (
              <div className="col-md-3">
                <label className="formLabel">Retail Group</label>
                <select id="retailgroupid" className="form-control" name="retailgroupid" defaultValue="" onChange={(e) => setChangeRetailgroup(e.target.value)} required>
                  <option value="" disabled>-- Select An Option --</option>
                  <option value="ALL">-- All --</option>
                  {items &&
                  items.map(({ GROUPID, NAME }) => (
                    <option key={GROUPID} value={GROUPID}>{NAME}</option>
                  ))}
                </select>
              </div>
            ) : ( "" )
          }
          {!isPromoReport && !isCostMarkup && !isPricingReport ? (
          <div className="col-md-3">
            <label className="formLabel">Start Date</label>
            <input type="date" className="form-control" id="startDate" name="startDate" value={changeStart} onChange={(e) => setChangeStart(e.target.value)} required />
          </div>
        ): ("")
          }
          {!isPromoReport && !isCostMarkup && !isPricingReport ? (
          <div className="col-md-3">
            <label className="formLabel">End Date</label>
            <input type="date" className="form-control" id="endDate" name="endDate" value={changeEnd} onChange={(e) => setChangeEnd(e.target.value)} required />
          </div>
          ): ("")
          }
        </div>
        <div className="row">
          <div className="form-group form-btn-group d-flex justify-content-between submitSpacing" id="maxForm">
            <button type="submit" className="btn btn-primary" id="viewreport">View Report</button> {doLoadReport && <><button className="btn btn-primary" id="printButton" onClick={handlePrint}><i class="bi bi-printer-fill"></i></button><button className="btn btn-primary" id="printButton" onClick={generatePDF}><i class="bi bi-file-earmark-pdf-fill"></i></button></>}
          </div>
        </div>
      </form>
      {
        doLoadReport ? (
          <div ref={componentRef} id="theReport">
            <SwitchReport report={loadReport} id={storeid} supplier={supplier} retailgroup={retailgroup} startDate={startDate} endDate={endDate}/>
          </div>
        ) : ("")
      }
    </div>
  );
}
