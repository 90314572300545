import React, {useState, useEffect} from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

export const Topups = () => {
  const url = "https://toolbelt.ctcgroup.com.au:2908/Topups/StoreID/";
  const storesurl = "https://toolbelt.ctcgroup.com.au:2908/MSO/stores/";
  const [stores, setStores ] = useState();
  const [removeurl, setRemoveurl] = useState('');
  const [items, setItems] = useState();
  const [isLoaded, setIsLoaded] = useState();
  const [error, setError] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [removed, setRemoved] = useState([]);

  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');

  const [storeid, setStoreid] = useState('');

  var count = 0;

  useEffect(() => {
        refreshToken();
  }, []);

  const getTopups = (storeid) => {
    fetch(url + storeid)
    .then((response) => {
      if(!response.ok) {
        throw new Error(`This is an HTTP error: The status is ${response.status}`);
      }
      return response.json();
    })
    .then((result) => {
      setItems(result);
      setRemoveurl ("https://toolbelt.ctcgroup.com.au:2908/Topups/remove/" + storeid);
      setError(null);
    })
    .catch((err) => {
      setError(err.message);
      setItems(null);
    })
    .finally(() => {
      setIsLoaded(true);
    });

  }

  const getStores = (storeid) => {
    fetch(storesurl + storeid)
    .then((response) => {
      if(!response.ok) {
        throw new Error(`This is an HTTP error: The status is ${response.status}`);
      }
      return response.json();
    })
    .then((result) => {
      setStores(result)
      setError(null);
    })
    .catch((err) => {
      setError(err.message);
      setItems(null);
    })
    .finally(() => {
      setIsLoaded(true);
    });
  }

  const refreshToken = async () => {
        try {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setExpire(decoded.exp);
            decoded.storeid.match("^[0-9]*$") ? getTopups(decoded.storeid) : getStores(decoded.storeid);
        } catch (error) {
            if (error.response) {
              if(window.location.href === "/") {

              } else {
                  window.location.replace("/");
              }
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setStoreid(decoded.storeid);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const removeRetailGroup = (e) => {
      e.preventDefault();
      removed.forEach(retailgroup => {
        console.log(removeurl);
        console.log(retailgroup.offerid, retailgroup.retailgroup);

        fetch(removeurl, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            offerid: retailgroup.offerid,
            retailgroup: retailgroup.retailgroup
          })
        })
        .then(response => {

        })
        .catch(err => {
          console.log(err);
        });
      });

      window.location.reload(false);
      alert("Top ups have successfully been removed.");
    }

  return(
    <div className="container-xl" id="storeTopups">
      <div><h1 className="inlinetitle">Store Topups</h1><div className="editButton" id="editButton"><a className="nav-link" id="addLink" href="/AddTopups">Add</a></div><div className="editButton" id="editButton" onClick={() => setShowEdit(!showEdit)}><button>Edit</button></div></div>
      {!isLoaded && <div>Loading... Please wait</div>}
      {error && (<div>{`There is a problem fetching store data - ${error}`}</div>)}
      {stores ? 
      <select id="store" className="form-control" name="store" defaultValue="" onChange={(e) => getTopups(e.target.value)} required>
              <option value="" disabled>-- Select Store --</option>
              {stores &&
              stores.map(({ id, name }) => (
                <option key={id} value={id}>{name}</option>
              ))}
            </select> : null}
      <div className="table-responsive-sm">
      <form onSubmit={removeRetailGroup}>
      <table className="table table-striped table-borderless" key={storeid}>
        <caption>Please Note: Any topups that haven't started yet will not appear in this list</caption>
        <thead>
          <tr>
            <th scope="col">Retail Group</th><th scope="col">Original</th><th scope="col">Promo</th><th scope="col">Topup</th><th scope="col">Price</th><th scope="col">Topup Start</th><th scope="col">Topup End</th>{showEdit && <th scope="col">Remove</th> }
          </tr>
        </thead>
        <tbody>
          {items &&
            items.map(({ OfferID, RetailGroup, Price, PromoAmount, TopupAmount, FinalPrice, TopupStart, TopupEnd}) => (
              <tr key={RetailGroup}>
                <td data-label="Retail Group">{RetailGroup}</td><td data-label="Original">${Price}</td><td data-label="Promo">${PromoAmount}</td><td data-label="Topup">${TopupAmount}</td><td data-label="Price">${FinalPrice}</td><td data-label="Topup Start">{TopupStart}</td><td data-label="Topup End">{TopupEnd}</td>{showEdit && <td className="tablecentred" data-label="Remove"><input type="checkbox" id={RetailGroup} value={OfferID} onChange={(e) => {if(e.target.checked === false) { const index = removed.map(object => object.retailgroup).indexOf(RetailGroup); removed.splice(index, 1); setRemoved([...removed]); } else {setRemoved([...removed, {"storeid": `${storeid}`, "offerid": e.target.value, "retailgroup": e.target.id, "removed": e.target.checked}]) }}}/></td>}
              </tr>
          ))}
        </tbody>
      </table>
      {showEdit &&
        <div className="form-group form-btn-group">
          <button type="submit" className="btn btn-primary" id="editSubmit">Submit Changes</button>
        </div>
      }
      </form>
      </div>
    </div>
  );
}
