import React, {useState, useEffect} from 'react';

export const ItemReport = ({id, supplier, retailgroup, startDate, endDate}) => {
  const url = "https://toolbelt.ctcgroup.com.au:2908/Reporting/Items/";
  const noItemurl = "https://toolbelt.ctcgroup.com.au:2908/Reporting/Items/NoItems/RetailGroups/";
  const storeid = {id};
  const checkSupplierid = {supplier};
  const checkRetailgroupid = {retailgroup};
  const [storeSupplier, setStoreSupplier] = useState("");
  const [storeRetailgroup, setStoreRetailgroup] = useState("");
  const checkStartDate = {startDate};
  const checkEndDate = {endDate};
  const [storeStartDate, setStoreStartDate] = useState("");
  const [storeEndDate, setStoreEndDate] = useState("");
  const [items, setItems] = useState([]);
  const [groupname, setGroupName] = useState();
  const [isLoaded, setIsLoaded] = useState();
  const [error, setError] = useState();

  const totalPrice = items.reduce((total, item) => {
    return Math.round((total + item.salesinc) * 100) / 100;
  }, 0);

  if(checkStartDate.startDate !== storeStartDate.startDate) {
    setStoreStartDate(checkStartDate);
  }

  if(checkEndDate.endDate !== storeEndDate.endDate) {
    setStoreEndDate(checkEndDate);
  }

  if(checkSupplierid.supplier !== storeSupplier.supplier) {
    setStoreSupplier(checkSupplierid);
  }

  if(checkRetailgroupid.retailgroup !== storeRetailgroup.retailgroup) {
    setStoreRetailgroup(checkRetailgroupid);
  }

  useEffect(() => {
      setIsLoaded(false);
      fetch(noItemurl + storeRetailgroup.retailgroup)
      .then((response) => {
        if(!response.ok) {
          throw new Error(`This is an HTTP error: The status is ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
          setGroupName(result);
          setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setGroupName(null);
      })
      .finally(() => {
        setIsLoaded(true);
      });

  }, [storeRetailgroup]);

  useEffect(() => {
    setIsLoaded(false);
      fetch(url + storeid.id +"/" + checkSupplierid.supplier +"/" + checkRetailgroupid.retailgroup +"/" + storeStartDate.startDate + "/" + storeEndDate.endDate)
      .then((response) => {
        if(!response.ok) {
          throw new Error(`This is an HTTP error: The status is ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
          setItems(result);
          setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setItems(null);
      })
      .finally(() => {
        setIsLoaded(true);
      });

  }, [storeSupplier, storeRetailgroup, storeStartDate, storeEndDate]);

  return(
    <div className="container-xl">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">{supplier} Sales {startDate} to {endDate}</div>
            {!isLoaded && <div>Loading... Please Wait</div>}
            {error && (<div>{`There is a problem fetching store data - ${error}`}</div>)}
            <table className="table table-striped table-borderless" id="itemReport">
              <thead>
                <tr>
                  <th className="tablecentred" scope="col">Item Name</th><th className="tablecentred" scope="col">QTY</th><th className="tablecentred" scope="col">Sales Inc GST</th>
                </tr>
              </thead>
              <tbody>
              {items &&
                items.map(({itemname, qty, salesinc}) => (
                  <tr key={itemname}>
                    <td className="tablecentred" data-label="Item Name">{itemname}</td><td className="tablecentred" data-label="QTY">{qty}</td><td className="tablecentred" data-label="Sales Inc GST">{salesinc}</td>
                  </tr>
              ))}
              {items < 1 &&
                <>
                {groupname &&
                  groupname.map(({GROUPID, NAME}) =>(
                  <tr>
                    <td className="tablecentred" data-label="Item Name" key={GROUPID}>{NAME}</td><td className="tablecentred" data-label="QTY">0</td><td className="tablecentred" data-label="Sales Inc GST">0.00</td>
                  </tr>
                  ))}
                </>
              }
              <tr>
                <td className="tablecentred">Total</td><td></td><td className="tablecentred">{totalPrice}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
