import React from 'react';

export const Footer = () => {
  return(
    <div id="footerContainer">
      <div id="footerContentContainer">
        <div id="footerLeft">
          <p>© CTC Group PTY LTD, 2022</p>
        </div>
        <div id="footerRight">
          <p><a href="mailto:it@ctcgroup.com.au"><img alt="it@ctcgroup.com.au" id="footerEmail" className="footerIcons" src="/envelope-solid.png" height="15px" width="19.5px" /></a><a href="tel:0295608122"><img alt="0295608122" id="footerPhone" className="footerIcons" src="/phone-solid.png" height="15px" width="15px" /></a></p>
        </div>
      </div>
    </div>
  );
}
