import React, { useEffect, useState } from 'react';
import { VictoryChart, VictoryBar} from 'victory';

export const SupplierReport = ({id, supplier, startDate, endDate}) => {
  const url = "https://toolbelt.ctcgroup.com.au:2908/Reporting/Suppliers/";
  const storeid = {id};
  const [storeSupplier, setStoreSupplier] = useState("");
  const storesup = {supplier};
  const checkStartDate = {startDate};
  const checkEndDate = {endDate};
  const [storeStartDate, setStoreStartDate] = useState("");
  const [storeEndDate, setStoreEndDate] = useState("");
  const [items, setItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState();
  const [error, setError] = useState();
  const fetchRetail = [];
  const fetchSupplierSales = [];
  const supplierName = [supplier, ""];

  const totalPrice = items.reduce((total, item) => {
    console.log(total);
    console.log(item.salesinc);
    return Math.round((total + item.salesinc) * 100) / 100;
  }, 0);

  if(storesup.supplier !== storeSupplier.supplier) {
    setStoreSupplier(storesup);
  }

  if(checkStartDate.startDate !== storeStartDate.startDate) {
    setStoreStartDate(checkStartDate);
  }

  if(checkEndDate.endDate !== storeEndDate.endDate) {
    setStoreEndDate(checkEndDate);
  }

  useEffect(() => {
      fetch(url + storeid.id +"/" + storesup.supplier + "/" + storeStartDate.startDate + "/" + storeEndDate.endDate)
      .then((response) => {
        if(!response.ok) {
          throw new Error(`This is an HTTP error: The status is ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        setItems(result);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setItems(null);
      })
      .finally(() => {
        setIsLoaded(true);
      });

  }, [storeSupplier, storeStartDate, storeEndDate]);

  return(
    <div className="container-xl">
      <div className="row">
        <div className="col-12 col-lg-8">
          <div className="card">
          {!isLoaded && <div>Loading... Please wait</div>}
          {error && (<div>{`There is a problem fetching store data - ${error}`}</div>)}
          {items &&
            items.map(({ supplier, retailgroup, qty, salesinc }) => {
              const retail = {retailgroup, qty}
              const supplierSales = {retailgroup, qty, salesinc}
              const suppliername = {supplier}
              supplierName.fill(suppliername);
              fetchRetail.push(retail);
              fetchSupplierSales.push(supplierSales);
              return;
          })}
            <div className="card-header">{supplierName[1].supplier} {startDate} to {endDate}</div>
            <VictoryChart width={800} height={1000} padding={{left: 230, right: 50, top: 30, bottom: 40}} domainPadding={10}>
              <VictoryBar horizontal
                style={{data: {fill: "#fcba00"}}}
                data={fetchRetail}
                barRatio={0.9}
                x="retailgroup"
                y="qty"
              />
            </VictoryChart>
          </div>
        </div>
        <div className="col-12 col-lg-4">
          <div className="card">
          <div className="card-header">Volume</div>
          <table className="table table-striped table-borderless" key={id}>
            <thead>
              <tr>
                <th className="tablecentred" scope="col">Retail Group</th><th className="tablecentred" scope="col">Qty</th><th className="tablecentred" scope="col">Sales</th>
              </tr>
            </thead>
            <tbody>
            {fetchSupplierSales &&
              fetchSupplierSales.map(({retailgroup, qty, salesinc}) => (
                <tr key={retailgroup}>
                  <td className="tablecentred" data-label="Retail Group">{retailgroup}</td><td className="tablecentred" data-label="Qty">{qty}</td><td className="tablecentred" data-label="Sales">{salesinc}</td>
                </tr>
            ))}
              <tr>
                <td className="tablecentred">Total</td><td></td><td className="tablecentred">{totalPrice}</td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
  );

}
