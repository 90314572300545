import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { MDBInput } from 'mdb-react-ui-kit';

export const Login = (props) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');

    useEffect(() => {
          props.hideNav(false);
    }, []);

    const Auth = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://toolbelt.ctcgroup.com.au:5000/login', {
                email: email,
                password: password
            });
            window.location.replace("/Dashboard");
        } catch (error) {
            if (error.response) {
              console.log(error.response);
                setMsg(error.response);
            }
        }
    }

    return (
      <section className="vh-100 gradient-custom">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div className="card bg-light text-black shadow-4" style={{borderRadius: '1rem'}}>
                <div className="card-body p-5 text-center">

                  <div className="mb-md-5 mt-md-4 pb-5">

                    <img alt="CTCGroup" src="/images/offCanvasLogo.png" width="295px" height="80px"/>
                    <p className="text-black-50 mb-5" style={{marginTop: "15px"}}>Please enter your login and password!</p>
                    <form onSubmit={Auth}>
                      <p className="has-text-centered">{msg}</p>
                      <div className="form-outline form-black mb-4">
                        <MDBInput label="Email" type="email" id="email" name="email" className="form-control form-control-lg" value={email} onChange={(e) => setEmail(e.target.value)} />
                      </div>

                      <div className="form-outline form-black mb-4">
                        <MDBInput label="Password" type="password" id="password" name="password" className="form-control form-control-lg" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="on"/>
                      </div>

                    <button className="btn btn-outline-dark btn-lg px-5 text-uppercase" type="submit">Login</button>
                    </form>

                  </div>

                  <div>
                    <p className="mb-0">Forgot Password? <a href="/forgot" className="text-black-50 fw-bold">Click Here</a>
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}
