import React, {useState, useEffect} from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

export const ChangeOwnership = () => {
  const url ="https://toolbelt.ctcgroup.com.au:2908/HO/ChangeOwnership/update";
  const [items, setItems] = useState([]);
  const [stores, setStores] = useState();
  const [startDate, setStartDate] = useState("");
  const [newCustNo, setNewCustNo] = useState();
  const [isLoaded, setIsLoaded] = useState();
  const [error, setError] = useState();
  const [custNo, setCustNo] = useState();

  const [supplier, setSupplier] = useState([{supplier: "BATA"}, {supplier: "ITA"}, {supplier: "PML"}]);
  const [supplierid, setSupplierid] = useState(null);

  const [bdm, setBdm] = useState("");
  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');
  const [storeid, setStoreid] = useState(null);

  useEffect(() => {
        refreshToken();
  }, []);

  useEffect(() => {
    fetch("https://toolbelt.ctcgroup.com.au:2908/HO/stores")
    .then((response) => {
      if(!response.ok) {
        throw new Error(`This is an HTTP error: The status is ${response.status}`);
      }
      return response.json();
    })
    .then((result) => {
      setStores(result);
      setError(null);
    })
    .catch((err) => {
      setError(err.message);
      setStores(null);
    })
    .finally(() => {
      setIsLoaded(true);
    });
  }, []);


  useEffect(() => {
    if(storeid != null && supplierid != null) {
      fetch("https://toolbelt.ctcgroup.com.au:2908/HO/stores/custno/" + storeid + "/" + supplierid)
      .then((response) => {
        if(!response.ok) {
          throw new Error(`This is an HTTP error: The status is ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        setCustNo(result[0].custno);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setCustNo(null);
      })
      .finally(() => {
        setIsLoaded(true);
      });
    }
  }, [storeid, supplierid])

  const refreshToken = async () => {
        try {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setBdm(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                window.location.replace("/");
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setBdm(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

  const addSupplier = (e) => {
    e.preventDefault();
    var newSupplier = {"supplierid": supplierid, "newCustNo": newCustNo, "oldCustNo": custNo, "startDate": startDate};
    setItems([...items, newSupplier]);
  }

  const addChangeOwnership = (e) => {
    e.preventDefault();
    console.log(items);

    for(const supplierid of items) {
      console.log(supplierid.supplierid, supplierid.newCustNo, supplierid.oldCustNo, supplierid.startDate);
      fetch(url, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          id: storeid,
          supplierid: supplierid.supplierid,
          newCustNo: supplierid.newCustNo,
          oldCustNo: supplierid.oldCustNo,
          startDate: supplierid.startDate,
          bdm: bdm
        }),
      })
      .then(result => {
        console.log("Done");
      })
      .catch(err => {
        console.log(err);
      });
    };
    alert("Change of Ownership been successfully filed!");
    window.location.replace("/ChangeOwnership");
  }

  return(
    <div className="container-xl">
      <div className="row">
        <div className="col-12 col-md-6 px-4 my-4">
          <div><h1 className="inlinetitle">New Change of Ownership</h1><div className="editButton" id="editButton"><a className="nav-link" id="addLink" href="/ChangeOwnership">Back</a></div></div>
          {!isLoaded && <div>Loading... Please wait</div>}
          {error && (<div>{`There is a problem fetching store data - ${error}`}</div>)}
          <form className="row g-3 was-validated" onSubmit={addSupplier}>
            <div className="mb-1">
              <label className="formLabel">Store</label>
              <select id="store" className="form-control" name="store" defaultValue="" onChange={(e) => setStoreid(e.target.value)} required>
                <option value="" disabled>-- Select Store --</option>
                {stores &&
                stores.map(({ name, storeid }) => (
                  <option key={storeid} value={storeid}>{name} - {storeid}</option>
                ))}
              </select>
            </div>
            <div className="mb-1">
              <label className="formLabel">Supplier</label>
              <select id="supplierid" className="form-control" name="supplierid" defaultValue="" onChange={(e) => setSupplierid(e.target.value)} required>
                <option value="" disabled>-- Select Supplier --</option>
                {supplier &&
                supplier.map(({supplier}) => (
                  <option key={supplier} value={supplier}>{supplier}</option>
                ))}
              </select>
            </div>
            <div className="mb-1">
              <label className="formLabel">Old Customer Number</label>
                <input type="number" className="form-control" id="oldCustNo" name="oldCustNo" defaultValue="" value={custNo} disabled />
            </div>
            <div className="mb-1">
              <label className="formLabel">New Customer Number</label>
              <input type="number" className="form-control" id="newCustNo" name="newCustNo" value={newCustNo} onChange={(e) => setNewCustNo(e.target.value)} required />
            </div>
            <div className="mb-1">
              <label className="formLabel">Start Date</label>
              <input type="date" className="form-control" id="startDate" name="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} required />
            </div>
            {bdm &&
            <div className="mb-1">
              <label className="formLabel">BDM</label>
              <input type="text" className="form-control" id="bdm" name="bdm" value={bdm} disabled />
            </div>
            }
            <div className="form-group form-btn-group mb-3">
              <button type="submit" className="btn btn-primary" id="editSubmit">Add Supplier</button>
            </div>
          </form>
        </div>
        <div className="col-12 col-md-6 px-2 my-4">
          {items.length > 0 &&
            <>
              <table className="table table-striped table-borderless">
                <thead>
                  <tr>
                    <th className="tablecentred" scope="col">Supplier</th><th className="tablecentred" scope="col">Old Customer Number</th><th className="tablecentred" scope="col">New Customer Number</th><th className="tablecentred" scope="col">Start Date</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map(({supplierid, oldCustNo, newCustNo, startDate}) => (
                    <tr key={supplierid}>
                      <td className="tablecentred" data-label="Supplier">{supplierid}</td><td className="tablecentred" data-label="Old Customer Number">{oldCustNo}</td><td className="tablecentred" data-label="New Customer Number">{newCustNo}</td><td className="tablecentred" data-label="Start Date">{startDate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="form-group form-btn-group my-4">
                <button className="btn btn-primary" id="editSubmit" onClick={addChangeOwnership}>Submit</button>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
}
