import React, {useState, useEffect} from 'react';
import { MDBInput } from 'mdb-react-ui-kit';

import axios from 'axios';

export const ForgotPassword = (props) => {

  const [email, setEmail] = useState('');
  const [messageFromServer, setMessageFromServer] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
        props.hideNav(false);
  }, []);

  const sendEmail = async(e) => {
    e.preventDefault();
    if( email === '' ) {
      setMessageFromServer('Email Required');
    } else {
      axios
          .post('https://toolbelt.ctcgroup.com.au:5000/forgotPassword', {
            email: email
          })
          .then(response => {
            console.log(response.data);
            setMessageFromServer(response.data);
          })
          .catch(err => {
          console.log(err);
            setErrorMessage(err.response.data);
            setError(true);
          });
    }
  }

  return (
    <section className="vh-100 gradient-custom">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card bg-light text-black shadow-4" style={{borderRadius: '1rem'}}>
              <div className="card-body p-5 text-center">
                <div className="mb-md-5 mt-md-4 pb-5">
                  <img alt="CTCGroup" src="/images/offCanvasLogo.png" width="295px" height="80px"/>
                  <p className="text-black-50 mb-5" style={{marginTop: "15px"}}>Please enter your email address!</p>
                  <form onSubmit={sendEmail}>
                    {
                      !error ? (<p className="has-text-centered">{messageFromServer}</p>) : (
                        <p className="has-text-centered">{errorMessage}</p>
                      )
                    }
                    <div className="form-outline form-black mb-4">
                      <MDBInput label="Email" type="email" id="email" name="email" className="form-control form-control-lg" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                  <button className="btn btn-outline-dark btn-lg px-5 text-uppercase" type="submit">Send Reset Email</button>
                  </form>
                  <a href="/" className="text-black-50 fw-bold" style={{display: 'block', marginTop: "15px"}}>Go Back</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
