import React, { useEffect, useState } from 'react';

export const HourlyReport = ({id, startDate, endDate}) => {

  const url = "https://toolbelt.ctcgroup.com.au:2908/Reporting/Hourly/";
  const storeid = {id};
  const checkStartDate = {startDate};
  const checkEndDate = {endDate};
  const [storeStartDate, setStoreStartDate] = useState("");
  const [storeEndDate, setStoreEndDate] = useState("");
  const [items, setItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState();
  const [error, setError] = useState();
  const fetchHours = [];

  const totalPrice = items.reduce((total, item) => {
    return Math.round((total + item.sales) * 100) / 100;
  }, 0);

  if(checkStartDate.startDate !== storeStartDate.startDate) {
    setStoreStartDate(checkStartDate);
  }

  if(checkEndDate.endDate !== storeEndDate.endDate) {
    setStoreEndDate(checkEndDate);
  }

  useEffect(() => {
      fetch(url + storeid.id +"/" +storeStartDate.startDate + "/" + storeEndDate.endDate)
      .then((response) => {
        if(!response.ok) {
          throw new Error(`This is an HTTP error: The status is ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        setItems(result);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setItems(null);
      })
      .finally(() => {
        setIsLoaded(true);
      });

  }, [storeStartDate, storeEndDate]);

  return(
    <div className="container-xl">
      <div className="row">
        {!isLoaded && <div>Loading... Please wait</div>}
        {error && (<div>{`There is a problem fetching store data - ${error}`}</div>)}
        {items &&
          items.map(({ date, hour, customers, sales }) => {
            const hours = {date, hour, customers, sales};
            fetchHours.push(hours);
            return;
        })}
        <div className="col-12 col-md-12">
          <div className="card">
            <div className="card-header">{startDate} to {endDate} Hourly Breakdown</div>
              <table className="table table-striped table-borderless" key={id}>
                <thead>
                  <tr>
                    <th className="tablecentred" scope="col">Date</th><th className="tablecentred" scope="col">Hour</th><th className="tablecentred" scope="col">Customers</th><th className="tablecentred" scope="col">Sales</th>
                  </tr>
                </thead>
                <tbody>
                  {fetchHours &&
                    fetchHours.map(({date, hour, customers, sales}) => (
                      <tr key={date + hour}>
                        <td className="tablecentred" data-label="Date">{date}</td><td className="tablecentred" data-label="Hour">{hour}</td><td className="tablecentred" data-label="Customers">{customers}</td><td className="tablecentred" data-label="Sales">{sales}</td>
                      </tr>
                  ))}
                  <tr>
                <td className="tablecentred">Total</td><td></td><td className="tablecentred">{totalPrice}</td>
              </tr>
                </tbody>
              </table>
            </div>
          </div>
      </div>
    </div>
  );
}
