import React, { useEffect, useState } from 'react';
import { PropTypes } from "prop-types";
import { VictoryPie } from 'victory';

export const ReportMarketShare = ({id, startDate, endDate}) => {

  const url = "https://toolbelt.ctcgroup.com.au:2908/Reporting/MarketShare/";
  const stickurl = "https://toolbelt.ctcgroup.com.au:2908/Reporting/MarketSticksGrams/";
  const storeid = {id};
  const checkStartDate = {startDate};
  const checkEndDate = {endDate};
  const [storeStartDate, setStoreStartDate] = useState("");
  const [storeEndDate, setStoreEndDate] = useState("");
  const [items, setItems] = useState();
  const [sticksGrams, setSticksGrams] = useState();
  const [isLoaded, setIsLoaded] = useState();
  const [sticksLoaded, setSticksLoaded] = useState();
  const [error, setError] = useState();
  const fetchMarketShare = [];
  const fetchSticks = [];

  if(checkStartDate.startDate !== storeStartDate.startDate) {
    setStoreStartDate(checkStartDate);
  }

  if(checkEndDate.endDate !== storeEndDate.endDate) {
    setStoreEndDate(checkEndDate);
  }

  useEffect(() => {
      fetch(url + storeid.id +"/" +storeStartDate.startDate + "/" + storeEndDate.endDate)
      .then((response) => {
        if(!response.ok) {
          throw new Error(`This is an HTTP error: The status is ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
          setItems(result);
          setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setItems(null);
      })
      .finally(() => {
        setIsLoaded(true);
      });

      fetch(stickurl + storeid.id +"/" +storeStartDate.startDate + "/" + storeEndDate.endDate)
      .then((response) => {
        if(!response.ok) {
          throw new Error(`This is an HTTP error: The status is ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
          setSticksGrams(result);
          setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setSticksGrams(null);
      })
      .finally(() => {
        setSticksLoaded(true);
      });

  }, [storeStartDate, storeEndDate]);

  const colors = [
    "#0069f9",
    "#fcba00",
    "#ffffff"
  ];

  const fontColors = [
    "#FFFFF",
    "#FFFFF",
    "#000000"
  ];

  const theme = {
    pie: {
      style: {
          data: {
            stroke: "#3d3d3d", strokeWidth: 0.5,
            fill : ({datum}) =>
              datum.x === 'PML' ? '#ffffff'
              : datum.x === 'BATA' ? '#0069f9'
              : '#fcba00'
          },
          labels: {
                fontSize: 12,
                fontWeight: "bold",
                fill : ({datum}) =>
                  datum.x === 'PML' ? '#000000'
                  : '#ffffff'
              }
          }
      }
  };


  return(
    <div className="container-xl">
    <div className="row">
      <div className="col-12 col-md-8">
        <div className="card">
        {!isLoaded && <div>Loading... Please wait</div>}
        {error && (<div>{`There is a problem fetching store data - ${error}`}</div>)}
        {items &&
          items.map(({ x, y, sticks }) => {
            const market = {x, y}
            const totalSticks = {x, y, sticks}
            fetchMarketShare.push(market);
            fetchSticks.push(totalSticks);
            return;
        })}

        <div className="card-header">Market Share {startDate} to {endDate}</div>
        <VictoryPie
        data={fetchMarketShare}
        labelRadius={({ innerRadius }) => innerRadius + 70 }
        labels={({ datum }) => `${datum.x}`}
        theme={theme}
        />
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="card">
        <div className="card-header">Volume</div>
        <table className="table table-striped table-borderless" key={id}>
          <thead>
            <tr>
              <th className="tablecentred" scope="col">Supplier</th><th className="tablecentred" scope="col">Market Share</th><th className="tablecentred" scope="col">Sticks/Grams</th>
            </tr>
          </thead>
          <tbody>
          {fetchSticks &&
            fetchSticks.map(({x, y, sticks}) => (
              <tr key={x}>
                <td className="tablecentred" data-label="Supplier">{x}</td><td className="tablecentred" data-label="Market Share">{y}%</td><td className="tablecentred" data-label="Sticks/Grams">{sticks}</td>
              </tr>
          ))}
          </tbody>
        </table>
        </div>
        <div className="card">
        <div className="card-header">Sticks/Grams</div>
        <table className="table table-striped table-borderless" key={id}>
          <thead>
            <tr>
              <th className="tablecentred" scope="col">Supplier</th><th className="tablecentred" scope="col">Sticks</th><th className="tablecentred" scope="col">Grams</th>
            </tr>
          </thead>
          <tbody>
          {sticksGrams &&
            sticksGrams.map(({Supplier, Sticks, Grams}) => (
              <tr key={Supplier}>
                <td className="tablecentred" data-label="Supplier">{Supplier}</td><td className="tablecentred" data-label="Sticks">{Sticks}</td><td className="tablecentred" data-label="Grams">{Grams}</td>
              </tr>
          ))}
          </tbody>
        </table>
        </div>
      </div>
    </div>
  </div>
  );
}

ReportMarketShare.propTypes = {
  id: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string
}
