import React, {useState, useEffect} from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

export const Markups = () => {
  const url ="https://toolbelt.ctcgroup.com.au:2908/Markups/StoreID/";
  const MSOurl = "https://toolbelt.ctcgroup.com.au:2908/MSO/Markups/";
  const storesurl = "https://toolbelt.ctcgroup.com.au:2908/MSO/stores/";
  const [showEdit, setShowEdit] = useState(false);
  const [items, setItems] = useState();
  const [isLoaded, setIsLoaded] = useState();
  const [isUpdating, setIsUpdating] = useState();
  const [error, setError] = useState();
  const [isMSO, setIsMSO] = useState(false);
  const [stores, setStores] = useState();
  const [selectedStore, setSelectedStore] = useState("");

  const [department, setDepartment] = useState([{department: "CARTONS"}, {department: "CIGARS"}, {department: "PACKETS"}, {department: "TOBACCO"}]);
  const [markup, setMarkup] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');

  const [storeid, setStoreid] = useState('');

  useEffect(() => {
        refreshToken();
  }, []);

  const getMarkups = (storeid) => {
    fetch(url + storeid)
    .then((response) => {
      if(!response.ok) {
        throw new Error(`This is an HTTP error: The status is ${response.status}`);
      }
      return response.json();
    })
    .then((result) => {
      setItems(result);
      setError(null);
    })
    .catch((err) => {
      setError(err.message);
      setItems(null);
    })
    .finally(() => {
      setIsLoaded(true);
    });
  }

  const getMSOMarkups = (storeid) => {
    fetch(MSOurl + storeid)
    .then((response) => {
      if(!response.ok) {
        throw new Error(`This is an HTTP error: The status is ${response.status}`);
      }
      return response.json();
    })
    .then((result) => {
      setItems(result);
      getStores(storeid);
      setIsMSO(true);
      setError(null);
    })
    .catch((err) => {
      setError(err.message);
      setItems(null);
    })
    .finally(() => {
      setIsLoaded(true);
    });
  }

  const getStores = (storeid) => {
    fetch(storesurl + storeid)
    .then((response) => {
      if(!response.ok) {
        throw new Error(`This is an HTTP error: The status is ${response.status}`);
      }
      return response.json();
    })
    .then((result) => {
      setStores(result)
      setError(null);
    })
    .catch((err) => {
      setError(err.message);
      setItems(null);
    })
    .finally(() => {
      setIsLoaded(true);
    });
  }

  const refreshToken = async () => {
        try {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setStoreid(decoded.storeid);
            setExpire(decoded.exp);
            decoded.storeid.match("^[0-9]*$")  ? getMarkups(decoded.storeid) : getMSOMarkups(decoded.storeid);
        } catch (error) {
            if (error.response) {
                window.location.replace("/");
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setStoreid(decoded.storeid);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

  const updateMarkups = (e) => {
    e.preventDefault();
    setIsUpdating(true);
    fetch(url + 'update/' + storeid, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: storeid,
        department: selectedDepartment,
        markup: markup
      })
    })
    .then(response => {
      window.location.reload(false);
      setIsUpdating(false);
      alert("Your Markups have been successfully applied.");
    })
    .catch(err => {
      console.log(err);
    });
  }

  const updateMSOMarkups = (e) => {
    e.preventDefault();
    setIsUpdating(true);
    fetch(url + 'update/' + selectedStore, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: selectedStore,
        department: selectedDepartment,
        markup: markup
      })
    })
    .then(response => {
      window.location.reload(false);
      setIsUpdating(false);
      alert("Your Markups have been successfully applied.");
    })
    .catch(err => {
      console.log(err);
    });
  }


  return(
    <div className="container-xl" id="storeMarkups">
      <div className="row">
        <div className="col-12 col-md-6">
        <div><h1 className="inlinetitle">Store Markups</h1> <div className="editButton" id="editButton" onClick={() => setShowEdit(!showEdit)}><button>Edit</button></div></div>
      {!isLoaded && <div>Loading... Please wait</div>}
      {error && (<div>{`There is a problem fetching store data - ${error}`}</div>)}
      { storeid &&
      <table className="table table-striped table-borderless" key={storeid}>
        <thead>
          <tr>
            {!storeid.match("^[0-9]*$") ?  <th className="tablecentred" scope="col">Store</th> : null}
            <th className="tablecentred" scope="col">Department</th><th className="tablecentred" scope="col">Markup</th>
          </tr>
        </thead>
        <tbody>
          {items &&
            items.map(({ store, department, percentage }) => (
              <tr key={store? store + " " + department : department}>
               {store ? <td className="tablecentred" data-label="Store">{store}</td> : null} <td className="tablecentred" data-label="Department">{department}</td><td className="tablecentred" data-label="Markup">{percentage}</td>
              </tr>
          ))}
        </tbody>
      </table>
    }
    </div>
    {showEdit &&
      <div id="editFields" className="col-12 col-md-6">
        <form id="updateMarkupsForm" className="row was-validated" onSubmit={selectedStore ? updateMSOMarkups : updateMarkups}>
          <h1 className="inlinetitle">Edit Markups</h1>
          {stores ? 
          <div>
            <label className="formLabel">Store</label>
            <select id="store" className="form-control" name="store" defaultValue="" onChange={(e) => setSelectedStore(e.target.value)} required>
              <option value="" disabled>-- Select Store --</option>
              {stores &&
              stores.map(({ id, name }) => (
                <option key={id} value={id}>{name}</option>
              ))}
            </select>
          </div> 
          : null}
          <div>
            <label className="formLabel">Department</label>
            <select id="department" className="form-control" name="department" defaultValue="" onChange={(e) => setSelectedDepartment(e.target.value)} required>
              <option value="" disabled>-- Select Department --</option>
              {department &&
              department.map(({ department }) => (
                <option key={department} value={department}>{department}</option>
              ))}
            </select>
          </div>
          <div className="mb-5">
            <label className="formLabel" htmlFor="markup">{selectedDepartment} Markup</label>
            <input type="number" id="markup" className="form-control" name="markup" value={markup} onChange={(e) => setMarkup(e.target.value.toUpperCase())} placeholder={selectedDepartment} maxLength="3" step="0.5" required/>
            <p className="invalid-feedback mb-3">Please provide percentage at 1 decimal place and 0.5% increments.</p>
          </div>
          <div className="form-group form-btn-group">
            <button type="submit" className="btn btn-primary" id="editSubmit">Submit Changes</button>
          </div>
          {isUpdating &&
            <div><p className="updating">Updating... Please Wait</p></div>
          }
        </form>
      </div>
      }
      </div>
    </div>
  );
}
