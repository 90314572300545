import React, {useState, useEffect} from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

export const StoreDetail = () => {
    const url ="https://toolbelt.ctcgroup.com.au:2908/Store/StoreID/";
    const [showEdit, setShowEdit] = useState(false);
    const [items, setItems] = useState();
    const [isLoaded, setIsLoaded] = useState();
    const [error, setError] = useState();
    const [address, setAddress] = useState("");
    const [street, setStreet] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [phone, setPhone] = useState("");

    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');

    const [storeid, setStoreid] = useState('');

    useEffect(() => {
          refreshToken();
    }, []);

    const getDetails = (storeid) => {
      fetch(url + storeid)
      .then((response) => {
        if(!response.ok) {
          throw new Error(`This is an HTTP error: The status is ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        setItems(result);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setItems(null);
      })
      .finally(() => {
        setIsLoaded(true);
      });
    }

    const refreshToken = async () => {
          try {
              const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
              setToken(response.data.accessToken);
              const decoded = jwt_decode(response.data.accessToken);
              setStoreid(decoded.storeid)
              setExpire(decoded.exp);
              getDetails(decoded.storeid);
          } catch (error) {
              if (error.response) {
                  window.location.replace("/");
              }
          }
      }

      const axiosJWT = axios.create();

      axiosJWT.interceptors.request.use(async (config) => {
          const currentDate = new Date();
          if (expire * 1000 < currentDate.getTime()) {
              const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
              config.headers.Authorization = `Bearer ${response.data.accessToken}`;
              setToken(response.data.accessToken);
              const decoded = jwt_decode(response.data.accessToken);
              setStoreid(decoded.storeid);
              setExpire(decoded.exp);
          }
          return config;
      }, (error) => {
          return Promise.reject(error);
      });

    const updateDetails = (e) => {
      e.preventDefault();

      fetch(url + 'update/' + storeid, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          id: storeid,
          address: address,
          street: street,
          zipCode: zipCode,
          phone: phone
        })
      })
      .then(response => {
        window.location.reload(false);
        alert("Details have been updated!");
      })
      .catch(err => {
        console.log(err);
      });
    }

    return(
      <div className="container-xl">
        <div className="row">
          <div className="col-12 col-md-6" id="StoreDetail">
            <div><h1 className="inlinetitle">Store Details</h1> <div className="editButton" id="editButton"><button onClick={() => setShowEdit(!showEdit)}>Edit</button></div></div>
            {!isLoaded && <div>Loading... Please wait</div>}
            {error && (<div>{`There is a problem fetching store data - ${error}`}</div>)}

              {items &&
                items.map(({ storeID, name, address, street, zipCode, phone }) => (
                <ul key={storeID}>
                  <li>
                    <h2>Store ID</h2>
                    <p>{storeID}</p>
                  </li>
                  <li>
                    <h2>Store Name</h2>
                    <p>{name}</p>
                  </li>
                  <li>
                    <h2>Shop/House Number</h2>
                    <p>{address}</p>
                  </li>
                  <li>
                    <h2>Street</h2>
                    <p>{street}</p>
                  </li>
                  <li>
                    <h2>Post Code</h2>
                    <p>{zipCode}</p>
                  </li>
                  <li>
                    <h2>Phone</h2>
                    <p>{phone}</p>
                  </li>
                </ul>
              ))}
              </div>

            {showEdit &&
            <div id="editFields" className="col-12 col-md-6">
              <form id="editStoreForm" className="row g-3 was-validated" onSubmit={updateDetails}>
                <h1 className="inlinetitle">Edit Store Details</h1>
                <div className="form-floating mb-3">
                  <input type="text" id="address" className="form-control" name="address" value={address} onChange={(e) => setAddress(e.target.value.toUpperCase())} placeholder="Name" maxLength="120" required/>
                  <label className="formLabel" htmlFor="address">Shop/House Number</label>
                  <div className="invalid-feedback mb-3">Please provide Shop/House number</div>
                </div>
                <div className="form-floating mb-3">
                  <input type="text" id="street" className="form-control" name="street" value={street} onChange={(e) => setStreet(e.target.value.toUpperCase())} placeholder="Street" maxLength="120" required/>
                  <label className="formLabel" htmlFor="street">Street</label>
                  <div className="invalid-feedback mb-3">Please provide Street</div>
                </div>
                <div className="form-floating mb-3">
                  <input type="text" id="zipCode" className="form-control" name="zipCode" value={zipCode} onChange={(e) => setZipCode(e.target.value.toUpperCase())} placeholder="Post Code" minLength="4" maxLength="4" required/>
                  <label className="formLabel" htmlFor="zipCode">Post Code</label>
                  <div className="invalid-feedback mb-3">Post Code must be 4-digits</div>
                </div>
                <div className="form-floating mb-3">
                  <input type="tel" id="phone" className="form-control" pattern="[0-9]{10}" name="phone" value={phone} onChange={(e) => setPhone(e.target.value.toUpperCase())} placeholder="Phone" minLength="7" maxLength="20" required/>
                  <label className="formLabel" htmlFor="phone">Phone</label>
                  <div className="invalid-feedback mb-3">Phone number must be 10-digits.</div>
                </div>
                <div className="form-group form-btn-group">
                  <button type="submit" className="btn btn-primary" id="editSubmit">Submit Changes</button>
                </div>
              </form>
            </div>
            }
          </div>
        </div>
      );

}
