import React, { useState, useEffect } from "react";
import axios from 'axios';
import jwt_decode from 'jwt-decode';

export const LivePriceboard = () => {

  const url = "https://toolbelt.ctcgroup.com.au:2908/SpecialGroups";
  const [specialgroup, setSpecialgroup] = useState();
  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');
  const [storeid, setStoreid] = useState("");
  const [ryo, setRYO] = useState([{index: 1, specialgroup: "placeholder", isRYO: false}]);
  const [finalArray, setFinalArray] = useState([{index: 1, specialgroup: "placeholder", pkt:0.00, ctn: 0.00, ryo: 0.00}]);
  const [withprice, setWithprice] = useState([]);
  const indexes = [{index: 1}, {index: 2}, {index: 3}, {index: 4}, {index: 5}, {index: 6}, {index: 7}, {index: 8}, {index: 9}, {index: 10},
                {index: 11}, {index: 12}, {index: 13}, {index: 14}, {index: 15}, {index: 16}, {index: 17}, {index: 18}, {index: 19}, {index: 20},
                {index: 21}, {index: 22}, {index: 23}, {index: 24}, {index: 25}, {index: 26}, {index: 27}, {index: 28}, {index: 29}, {index: 30}];
  var splitValues;
  const [step, setStep] = useState(1);

  const [ryoChange, setRYOChange] = useState();
  const [pktChange, setPKTChange] = useState();
  const [ctnChange, setCTNChange] = useState();

  var accepted = [{index: 1, accepted: false}];

  useEffect(() => {
        refreshToken();
  }, []);

  const refreshToken = async () => {
        try {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setStoreid(decoded.storeid);
            getSpecialgroup();
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                window.location.replace("/");
            }
        }
    }

    const getSpecialgroup = () => {
          fetch(url)
          .then((response) => {
            if(!response.ok) {
              throw new Error(`This is an HTTP error: The status is ${response.status}`);
            }
            return response.json();
          })
          .then((result) => {
            setSpecialgroup(result);
          })
          .catch((err) => {
            setSpecialgroup(null);
          })
          .finally(() => {
          });
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setStoreid(decoded.storeid);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const handleChange = (e) => {
      e.preventDefault();

      var splitIndex = e.target.value.split(",");
      var index = parseInt(splitIndex[0]);

      if(ryo.map(e => e.index).indexOf(index) > -1 ) {
         const newIndex = ryo.map(e => e.index).indexOf(index);
         ryo.splice(newIndex, 1);
         setRYO([...ryo]);

         splitValues = e.target.value.split(",");

         var depCode = splitValues[2];
         depCode === 'RYO' ? setRYO((prevRYO) => [...prevRYO, {index: parseInt(splitValues[0]), specialgroup: splitValues[1] , isRYO: true}]) : setRYO((prevRYO) => [...prevRYO, {index: parseInt(splitValues[0]), specialgroup: splitValues[1] , isRYO: false}]);
       } else {
         splitValues = e.target.value.split(",");
         var depCode = splitValues[2];
         depCode === 'RYO' ? setRYO((prevRYO) => [...prevRYO, {index: parseInt(splitValues[0]), specialgroup: splitValues[1] , isRYO: true}]) : setRYO((prevRYO) => [...prevRYO, {index: parseInt(splitValues[0]), specialgroup: splitValues[1] , isRYO: false}]);

      }
    }

    function compare(a,b) {
      if(a.index < b.index) {
        return -1;
      }
      if(a.index > b.index) {
        return 1;
      }
      return 0;
    }

    const handleNext = (e) => {
      e.preventDefault();
      ryo.sort(compare);
      setStep(2);
    }

    const handleAccepted = (isRYO) => {

          var index1;
          var specialgroup1;
          var index2;
          var specialgroup2;
          var ryoPrice;
          var pktPrice;
          var ctnPrice;

          if(isRYO === true) {
            index1 = parseInt(ryoChange.index);
            specialgroup1 = ryoChange.specialgroup;
            ryoPrice = ryoChange.ryo;
            if (index1 === 1) {
              setFinalArray([{index: index1, specialgroup: specialgroup1, pkt: 0.00, ctn: 0.00, ryo: ryoPrice}]);
            } else {
              setFinalArray((prevFinalArray) => [...prevFinalArray, {index: index1, specialgroup: specialgroup1, pkt: 0.00, ctn: 0.00, ryo: ryoPrice}]);
            }
            accepted[parseInt(index1)] = true;
          } else  {
            index1 = parseInt(pktChange.index);
            specialgroup1 = pktChange.specialgroup;
            pktPrice = pktChange.pkt;
            index2 = parseInt(ctnChange.index);
            specialgroup2 = ctnChange.specialgroup;
            ctnPrice = ctnChange.ctn;
            if(index1 === 1) {
              setFinalArray([{index: index1, specialgroup: specialgroup1, pkt: pktPrice, ctn: ctnPrice, ryo: 0.00}]);
            } else {
                setFinalArray((prevFinalArray) => [...prevFinalArray, {index: index1, specialgroup: specialgroup1, pkt: pktPrice, ctn: ctnPrice, ryo: 0.00}]);
            }
            accepted[parseInt(index1)] = true;
            console.log(accepted);
            console.log(accepted[parseInt(index1)]);
          }

          console.log(finalArray);
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      splitValues = e.target.name.split(",");

      var isRYO;
      var isPKT;
      var isCTN;
      splitValues[2] === "RYO" ? isRYO = e.target.value
      : splitValues[2] === "PKT" ? isPKT = e.target.value
      : splitValues[2] === "CTN" ? isCTN = e.target.value
      : isRYO = 0;

      setWithprice((prevWithprice) => [...prevWithprice, {index: parseInt(splitValues[0]), specialgroup: splitValues[1], ryo: isRYO, pkt: isPKT, ctn: isCTN}])
      console.log(withprice);
    }

  return(
    <div className="container">
      <h1 className="inlinetitle">Live priceboard</h1>
      {step === 1 ? (
        <form id="priceboard" style={{marginBottom: "1rem"}}>
        {specialgroup ? (
          <>
            <div className="row">
             {indexes && indexes.map(({index}) => (
               <div className="col-12 col-md-6">
                  <div className="row" style={{marginTop: "5px", marginBottom: "5px"}}>
                    <div className="col-12 col-lg-4">
                      <h4 style={{marginTop: "10px"}}>Position {index}</h4>
                    </div>
                    <div className="col-12 col-lg-8">
                      <select id={`specialgroup ${index}`} className="form-control" name={`specialgroup ${index}`} defaultValue="" onChange={(e) => {handleChange(e);}} required>
                        <option value="" disabled>-- Select Special Group --</option>
                        {specialgroup && specialgroup.map(({specialgroup, departmentcode, supplier}) => (
                          <option value={index + "," + specialgroup + "," + departmentcode}>{specialgroup}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button className="btn btn-primary" id="editSubmit" style={{marginTop: "20px"}} onClick={(e) => handleNext(e)}>Next</button>
          </>
        ): null}
        </form>
      ) : step === 2 ? (
        <form id="priceboardStep2" onSubmit={handleSubmit} style={{marginBottom: "1rem"}}>
          {ryo && ryo.map(({index, specialgroup, isRYO}) => (
            <>
              <div className="row" style={{marginBottom: "10px"}}>
                <div className="col-12 col-md-6 d-flex align-items-center mobileSpacing">
                  <h5 style={{marginBottom: "0px"}}>{index} <span style={{fontWeight: "300", marginLeft: "10px"}}>{specialgroup}</span></h5>
                </div>
                {isRYO ? (
                  <div className="col-12 col-md-4 d-flex align-items-center mobileSpacing">
                    <label className="formLabel" style={{marginRight: "5px", marginBottom: "0px"}}>RYO</label>
                    <input className="form-control" style={{marginBottom: "0px !important"}} type="number" step="0.01" name={index + "," + specialgroup + "," + "RYO"} onChange={(e) => setRYOChange({index: index, specialgroup: specialgroup, ryo: e.target.value})} required></input>
                  </div>
                ) : (
                  <>
                    <div className="col-12 col-md-2 d-flex align-items-center mobileSpacing">
                      <label className="formLabel" style={{marginRight: "5px", marginBottom: "0pxt"}}>PKT</label>
                      <input className="form-control" style={{marginBottom: "0px !important"}} type="number" step="0.01" name={index + "," + specialgroup + "," + "PKT"} onChange={(e) => setPKTChange({index: index, specialgroup: specialgroup, pkt: e.target.value})} required></input>
                    </div>
                    <div className="col-12 col-md-2 d-flex align-items-center mobileSpacing">
                      <label className="formLabel" style={{marginRight: "5px", marginBottom: "0px"}}>CTN</label>
                      <input className="form-control" style={{marginBottom: "0px !important"}} type="number" step="0.01" name={index + "," + specialgroup + "," + "CTN"} onChange={(e) => setCTNChange({index: index, specialgroup: specialgroup, ctn: e.target.value})} required></input>
                    </div>
                  </>
                )}
                {accepted[parseInt(index - 1)] !== true ? (
                  <div className="col-12 col-md-2 d-flex align-items-center mobileSpacingBtn">
                    <button className="btn btn-primary" id="editSubmit" style={{marginBottom: "0px"}} onClick={() => { accepted[parseInt(index)] = true; handleAccepted(isRYO);}}>Accept</button>
                  </div>
                ) : (
                  <div className="col-12 col-md-2">
                    <svg src="/check-solid.svg" ></svg>
                  </div>
                )}
              </div>
            </>
          ))}
          <button className="btn btn-primary" type="submit" id="editSubmit" style={{marginTop: "20px"}}>Finish</button>
        </form>) : (<></>)}
    </div>
  );
}
