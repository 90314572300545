import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const NavBar = () => {

  const [isHO, setIsHO] = useState(false);

  const [storeid, setStoreid] = useState('');

  useEffect(() => {
        getStoreID();

  }, []);

  const getStoreID = async () => {
        try {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            const decoded = jwt_decode(response.data.accessToken);
            setStoreid(await decoded.storeid);

            if(await decoded.storeid === 'HO') {
              setIsHO(true);
            } else {
              setIsHO(false);
            }

        } catch (error) {
            if (error.response) {
                console.log(error.response);
            }
        }
  }

  const Logout = async () => {
      try {
          await axios.delete('https://toolbelt.ctcgroup.com.au:5000/logout');
          window.location.replace("/");
      } catch (error) {
          console.log(error);
      }
  }

  return(
      <Navbar collapseOnSelect expand="lg" bg="primary" variant='dark' sticky="top" id='navContainer'>
           <Navbar.Brand>
              <img id="groupLogo" alt="CTCGroup" src="/images/siteLogo.png" width="295px" height="80px"/>
           </Navbar.Brand>
           <Navbar.Toggle id="main-navigation-toggle"/>
            <Navbar.Offcanvas id="responsive-navbar-nav" placement="end">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="CTCSiteLogo"><img id="offCanvas" alt="CTCGroup" src="/images/offCanvasLogo.png" width="295px" height="80px"/></Offcanvas.Title>
              </Offcanvas.Header>
              <Nav id="overlay-content">
             {
               isHO ? (
                 <>
                   <Nav.Link href="/Dashboard">Home</Nav.Link>
                   <Nav.Link href="/Reporting">Reporting</Nav.Link>
                   <Nav.Link href="/ChangeOwnership">Change of Ownership</Nav.Link>
                   <Nav.Link onClick={Logout}>Log Out</Nav.Link>
                 </>
               ) : (
                 <>
                   <Nav.Link href="/Dashboard">Home</Nav.Link>
                   <Nav.Link href="/Markups">Markups</Nav.Link>
                   <Nav.Link href="/Topups">Topups</Nav.Link>
                   <Nav.Link href="/Reporting">Reporting</Nav.Link>
                   <Nav.Link href="/Store">Store Details</Nav.Link>
                   <Nav.Link onClick={Logout}>Log Out</Nav.Link>
                 </>
               )
             }
             </Nav>
          </Navbar.Offcanvas>
      </Navbar>
  );
}
