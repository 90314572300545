import React, {useState, useEffect} from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

export const AddTopups = () => {
  const url = "https://toolbelt.ctcgroup.com.au:2908/Topups/RetailGroups/";
  const updateurl = "https://toolbelt.ctcgroup.com.au:2908/Topups/StoreID/update/";
  const [items, setItems] = useState();
  const [isLoaded, setIsLoaded] = useState();
  const [error, setError] = useState();
  const [groupid, setGroupid] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [topup, setTopup] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [theTopups, setTheTopups] = useState([]);

  const [username, setUsername] = useState('');
  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');

  const [storeid, setStoreid] = useState('');

  useEffect(() => {
        refreshToken();
  }, []);

  const getRetailgroup = (storeid) => {
        fetch(url + storeid)
        .then((response) => {
          if(!response.ok) {
            throw new Error(`This is an HTTP error: The status is ${response.status}`);
          }
          return response.json();
        })
        .then((result) => {
          setItems(result);
          setError(null);
        })
        .catch((err) => {
          setError(err.message);
          setItems(null);
        })
        .finally(() => {
          setIsLoaded(true);
        });
  }

  const refreshToken = async () => {
        try {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUsername(decoded.name);
            setStoreid(decoded.storeid);
            setExpire(decoded.exp);
            getRetailgroup(decoded.storeid);
        } catch (error) {
            if (error.response) {
                window.location.replace("/");
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setStoreid(decoded.storeid);
            setUsername(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const addTopupsToArray = (e) => {
      e.preventDefault();
      var newTopups = {"id": storeid, "groupid": groupid, "topup": topup, "discountType": discountType, "startDate": startDate, "endDate": endDate};
      setTheTopups([...theTopups, newTopups]);
    }


  const addTopups = (e) => {
    e.preventDefault();

    theTopups.forEach( groupid => {
      console.log(groupid.groupid, groupid.topup, groupid.discountType, groupid.startDate, groupid.endDate);
      fetch(updateurl + storeid, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          id: storeid,
          groupid: groupid.groupid,
          topup: groupid.topup,
          discountType: groupid.discountType,
          startDate: groupid.startDate,
          endDate: groupid.endDate
        }),
      })
      .then(response => {

      })
      .catch(err => {console.log(err);
      });
    });

    alert("Top ups have been successfully applied!");
    window.location.replace("/Topups");
  }

  return(
    <div className="container-xl" id="addTopups">
      <div><h1 className="inlinetitle">Add Topups</h1><div className="editButton" id="editButton"><a className="nav-link" id="addLink" href="/Topups">Back</a></div></div>
      {!isLoaded && <div>Loading... Please wait</div>}
      {error && (<div>{`There is a problem fetching store data - ${error}`}</div>)}
      <div className="row">
        <div className="col-12 col-md-6 px-4 my-4">
          <form id="addTopupsForm" className="row g-3 was-validated" onSubmit={addTopupsToArray}>
            <div className="mb-3">
              <label className="formLabel">Retail Group</label>
              <select id="groupid" className="form-control" name="groupid" defaultValue="" onChange={(e) => setGroupid(e.target.value)} required>
                <option value="" disabled>-- Select Retail Group --</option>
                {items &&
                items.map(({ GROUPID, NAME }) => (
                  <option key={GROUPID} value={GROUPID}>{NAME}</option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label className="formLabel">Discount Type</label>
              <select id="priceType" className="form-control" name="priceType"  defaultValue="" onChange={(e) => setDiscountType(e.target.value)} required>
                <option value="" disabled>-- Select Discount Type --</option>
                <option name="Discount Amount" value="Discount Amount">Discount Amount</option>
                <option name="Sales Price" value="Sales Price">Sales Price</option>
              </select>
            </div>
            <div className="mb-3">
              <label className="formLabel">Top up</label>
              <input type="number" className="form-control" id="topup" name="topup" value={topup} onChange={(e) => setTopup(e.target.value)} step="0.01" required />
            </div>
            <div className="mb-3">
              <label className="formLabel">Start Date</label>
              <input type="date" className="form-control" id="startDate" name="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} required />
            </div>
            <div className="mb-3">
              <label className="formLabel">End Date</label>
              <input type="date" className="form-control" id="endDate" name="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} required />
            </div>
            <div className="form-group form-btn-group">
              <button type="submit" className="btn btn-primary" id="editSubmit">Add Topup</button>
            </div>
          </form>
        </div>
        <div className="col-12 col-md-6 px-2 my-4">
              {theTopups.length > 0 &&
              <>
                <table className="table table-striped table-borderless">
                  <thead>
                    <tr>
                      <th className="tablecentred" scope="col">Group ID</th><th className="tablecentred" scope="col">Discount Type</th><th className="tablecentred" scope="col">Topup</th><th className="tablecentred" scope="col">Start Date</th><th className="tablecentred" scope="col">End Date</th>
                    </tr>
                  </thead>
                  <tbody>
                {theTopups.map(({groupid, discountType, topup, startDate, endDate}) => (
                  <tr key={groupid}>
                    <td className="tablecentred" data-label="Group ID">{groupid}</td><td className="tablecentred" data-label="Discount Type">{discountType}</td><td className="tablecentred" data-label="Topup">{topup}</td><td className="tablecentred" data-label="Start Date">{startDate}</td><td className="tablecentred" data-label="End Date">{endDate}</td>
                  </tr>
                ))}
                </tbody>
              </table>
              <div className="form-group form-btn-group my-4">
                <button className="btn btn-primary" id="editSubmit" onClick={addTopups}>Submit</button>
              </div>
            </>
            }
        </div>
      </div>
    </div>
  );
}
