import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MDBInput } from 'mdb-react-ui-kit';

 export const ResetPassword = (props) => {
   const [username, setUsername] = useState('');
   const [password, setPassword] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');
   const [updated, setUpdated] = useState(false);
   const [isLoading, setIsLoading] = useState(true);
   const [error, setError] = useState('');

   const url = new URL(window.location.href);
   const query = new URLSearchParams(url.search);
   const token = query.get('token');

   useEffect(() => {
     props.hideNav(false);

      axios
      .get('https://toolbelt.ctcgroup.com.au:5000/reset', {
        params: {
          resetPasswordToken: token,
        }
      })
      .then(response => {
        console.log(response);
        if(response.status === 200) {
          setUsername(response.data.username);
          setUpdated(false);
          setIsLoading(false);
        } else {
          setUpdated(false);
          setIsLoading(false);
          setError('Reset Password Link Invalid or Expired');
        }
      })
      .catch(error => {
        setIsLoading(false);
        setError(error.response.data.msg);
        console.log(error.response.data.msg);
      })
   }, []);

   const updatePassword = (e) => {
     e.preventDefault();

     axios
      .put('https://toolbelt.ctcgroup.com.au:5000/updatePasswordViaEmail', {
        email: username,
        password: password,
        confPassword: confirmPassword,
      })
      .then(response => {
        console.log(response.data);
        if(response.data === 'Password Updated') {
          setUpdated(true);
          setError(false);
        } else {
          setUpdated(false);
          setError(response.data.msg);
        }
      })
      .catch(error => {
        console.log(error.data);
      })
   }

   return (
     <section className="vh-100 gradient-custom">
       <div className="container py-5 h-100">
         <div className="row d-flex justify-content-center align-items-center h-100">
           <div className="col-12 col-md-8 col-lg-6 col-xl-5">
             <div className="card bg-light text-black shadow-4" style={{borderRadius: '1rem'}}>
               <div className="card-body p-5 text-center">
                 <div className="mb-md-5 mt-md-4 pb-5">
                   <img alt="CTCGroup" src="/images/offCanvasLogo.png" width="295px" height="80px"/>
                   <p className="text-black-50 mb-5" style={{marginTop: "15px"}}>Please enter your new password!</p>
                   {isLoading &&
                     <p>Loading...</p>
                   }
                  {
                     !error ? (
                     <form onSubmit={updatePassword}>
                       <p className="has-text-centered">{error}</p>
                       <div className="form-outline form-black mb-4">
                         <MDBInput label="Password" type="password" id="password" name="password" className="form-control form-control-lg" value={password} onChange={(e) => setPassword(e.target.value)} />
                       </div>
                       <div className="form-outline form-black mb-4">
                         <MDBInput label="Confirm Password" type="password" id="confirmPassword" name="confirmPassword" className="form-control form-control-lg" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                       </div>
                     <button className="btn btn-outline-dark btn-lg px-5 text-uppercase" type="submit">Change Password</button>
                     </form>
                    ) : (
                      <>
                        {error}
                        <a href="/" className="text-black-50 fw-bold" style={{display:'block', marginTop: '10px'}}>Go Back</a>
                      </>)
                  }
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </section>
   );
 }
