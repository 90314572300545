import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import {Login} from './components/Login';
import {Register} from './components/Register';
import {Dashboard} from './sections/Dashboard';
import {StoreDetail}  from './sections/StoreDetail';
import {Markups} from './sections/Markups';
import {Topups} from './sections/Topups';
import {AddTopups} from './sections/AddTopups';
import {ViewChangeOwnership} from './sections/ho/ViewChangeOwnership';
import {ChangeOwnership} from './sections/ho/ChangeOwnership';
import {Reporting} from './sections/Reporting';
import {LivePriceboard} from './sections/LivePriceboard';

import {NavBar} from './components/NavBar';
import {Footer} from './components/Footer';
import { Error } from './sections/Error';
import {ForgotPassword} from './components/ForgotPassword';
import {ResetPassword} from './components/ResetPassword';

const renderLoader = () => <p>Loading...</p>;

const App = () => {

  const [showNav, setShowNav] = useState(true);


    return(
      <>
          <div id="root">
              <BrowserRouter>
              { showNav &&
                <NavBar />
              }
                <Routes>
                  <Route exact path="/" element={<Login hideNav={setShowNav}/>}/>
                  <Route path="/register" element={<Register hideNav={setShowNav}/>}/>
                  <Route path="/dashboard" element={<Dashboard />}/>
                  <Route path="/markups" element={<Markups />}/>
                  <Route path="/topups" element={<Topups />}/>
                  <Route path="/addtopups" element={<AddTopups />}/>
                  <Route path="/changeownership" element={<ViewChangeOwnership />}/>
                  <Route path="/addchangeownership" element={<ChangeOwnership />}/>
                  <Route path="/reporting" element={<Reporting />}/>
                  <Route path="/store" element={<StoreDetail />}/>
                  <Route path="/forgot" element={<ForgotPassword hideNav={setShowNav}/>}/>
                  <Route path="/reset" element={<ResetPassword hideNav={setShowNav}/>}/>
                  <Route path="/priceboard" element={<LivePriceboard />} />

                  <Route path="/*" element={<Error hideNav={setShowNav}/>}/>
                </Routes>
              </BrowserRouter>
            </div>
        { showNav &&
          <Footer />
        }
        </>
       );
};

export default App;
