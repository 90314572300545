import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import App from './App';
import * as ServiceWorker from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
const pjson = require('../package.json');

axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

try {
    // Register worker
    ServiceWorker.register(pjson.version);
} catch (e) {
    console.error('Service Worker Failed', e);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
