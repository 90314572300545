import React, {useEffect} from 'react';

export const Error = (props) => {

  useEffect(() => {
        props.hideNav(false);
  }, []);

  return(
    <div className='container-fluid' id="container404">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <img alt="ctcgroup" width="100%" src="/images/siteLogo.png"/>
            <h1 id="text404">404</h1>
            <p id="disclaimer404">Sorry, this page doesn't exist!</p>
            <p id="disclaimer404"><a id="goback" href="/">Go Back</a></p>
          </div>
        </div>
      </div>
    </div>
  );
}
