import React, {useState, useEffect} from 'react';

export const CostMarkupReport = ({id, supplier}) => {
  const url = "https://toolbelt.ctcgroup.com.au:2908/Reporting/CostMarkup/";
  const storeid = {id};
  const checkSupplier = {supplier};
  const [storeSupplier, setStoreSupplier] = useState("");
  const [items, setItems] = useState();
  const [isLoaded, setIsLoaded] = useState();
  const [error, setError] = useState();

  if(checkSupplier.supplier !== storeSupplier.supplier) {
    setStoreSupplier(checkSupplier);
  }

  useEffect(() => {
      fetch(url + storeid.id +"/" + storeSupplier.supplier )
      .then((response) => {
        if(!response.ok) {
          throw new Error(`This is an HTTP error: The status is ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        setItems(result);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setItems(null);
      })
      .finally(() => {
        setIsLoaded(true);
      });

  }, [storeSupplier]);

  return(
    <div className="container-xl">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">Cost and Markup Report for {supplier}</div>
            {!isLoaded && <div>Loading... Please wait</div>}
            {error && (<div>{`There is a problem fetching store data - ${error}`}</div>)}
            <table className="table table-striped table-borderless">
              <thead>
                <tr>
                  <th className="tablecentred" scope="col">Department</th><th className="tablecentred" scope="col">Retail Group</th><th className="tablecentred" scope="col">HO Cost</th><th className="tablecentred" scope="col">Store Cost</th><th className="tablecentred" scope="col">Original Price</th><th className="tablecentred" scope="col">Markup</th><th className="tablecentred" scope="col">Correct</th>
                </tr>
              </thead>
              <tbody>
              {items &&
                items.map(({department, retailgroup, hocost, storecost, original, markup, correct}) => (
                  <tr key={retailgroup}>
                    <td className="tablecentred" data-label="Department">{department}</td><td className="tablecentred" data-label="Retail Group">{retailgroup}</td><td className="tablecentred" data-label="HO Cost">{hocost}</td><td className="tablecentred" data-label="Store Cost">{storecost}</td><td className="tablecentred" data-label="Original Price">{original}</td><td className="tablecentred" data-label="Markup">{markup}</td><td className="tablecentred" data-label="Correct">{correct}</td>
                  </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
