import React from 'react';
import { ReportMarketShare } from './ReportMarketShare';
import { Departments } from './Departments';
import { SupplierReport } from './SupplierReport';
import { HourlyReport } from './HourlyReport';
import { ItemReport } from './ItemReport';
import { PromoReport } from './PromoReport';
import { CostMarkupReport } from './CostMarkupReport';
import { PricingReport } from './PricingReport';

export const SwitchReport = ({report, id, supplier, retailgroup, startDate, endDate}) => {

    const returnReport = (report) => {
      switch(report.report) {
        case "ReportMarketShare":
          return <ReportMarketShare id={id} startDate={startDate} endDate={endDate}/>;
        break;
        case "Departments":
          return <Departments id={id} startDate={startDate} endDate={endDate}/>;
        break;
        case "SupplierReport":
          return <SupplierReport id={id} supplier={supplier} startDate={startDate} endDate={endDate}/>;
        break;
        case "HourlyReport":
          return <HourlyReport id={id} startDate={startDate} endDate={endDate}/>;
        break;
        case "ItemReport":
          return <ItemReport id={id} supplier={supplier} retailgroup={retailgroup} startDate={startDate} endDate={endDate}/>;
        break;
        case "PromoReport":
          return <PromoReport id={id} supplier={supplier}/>;
        break;
        case "CostMarkupReport":
          return <CostMarkupReport id={id} supplier={supplier}/>;
        break;
        case "PricingReport":
          return <PricingReport id={id} supplier={supplier}/>;
        break;
        default :
          break;
      }
    };

  return(
    <div>
    {returnReport({report})}
    </div>
  );

}
