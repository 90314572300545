import React, {useState, useEffect} from 'react';

export const PricingReport = ({id, supplier}) => {

  const url = "https://toolbelt.ctcgroup.com.au:2908/Reporting/Pricing/";
  const storeid = {id};
  const checkSupplier = {supplier};
  const [storeSupplier, setStoreSupplier] = useState("");
  const [items, setItems] = useState();
  const [isLoaded, setIsLoaded] = useState();
  const [error, setError] = useState();

  if(checkSupplier.supplier !== storeSupplier.supplier) {
    setStoreSupplier(checkSupplier);
  }

  useEffect(() => {
      fetch(url + storeid.id +"/" + storeSupplier.supplier )
      .then((response) => {
        if(!response.ok) {
          throw new Error(`This is an HTTP error: The status is ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        setItems(result);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setItems(null);
      })
      .finally(() => {
        setIsLoaded(true);
      });

  }, [storeSupplier]);

  return(
    <div className="container-xl">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">Pricing Report for {supplier}</div>
            {!isLoaded && <div>Loading... Please wait</div>}
            {error && (<div>{`There is a problem fetching store data - ${error}`}</div>)}
            <table className="table table-striped table-borderless">
              <thead>
                <tr>
                  <th className="tablecentred" scope="col">Department</th><th className="tablecentred" scope="col">Retail Group</th><th className="tablecentred" scope="col">Price</th><th className="tablecentred" scope="col">Promo</th><th className="tablecentred" scope="col">Topup</th><th className="tablecentred" scope="col">Final Price</th><th className="tablecentred" scope="col">Start Date</th><th className="tablecentred" scope="col">End Date</th>
                </tr>
              </thead>
              <tbody>
              {items &&
                items.map(({Department,RetailGroup, Price, PromoAmount, TopupAmount, FinalPrice, PromoStart, PromoEnd}) => (
                  <tr key={RetailGroup}>
                    <td className="tablecentred" data-label="Department">{Department}</td><td className="tablecentred" data-label="Retail Group">{RetailGroup}</td><td className="tablecentred" data-label="Price">{Price}</td><td className="tablecentred" data-label="Promo">{PromoAmount}</td><td className="tablecentred" data-label="Topup">{TopupAmount}</td><td className="tablecentred" data-label="Final Price">{FinalPrice}</td><td className="tablecentred" data-label="Start Date">{PromoStart}</td><td className="tablecentred" data-label="End Date">{PromoEnd}</td>
                  </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
