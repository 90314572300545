import React, {useState, useEffect} from "react";
import axios from 'axios';
import jwt_decode from 'jwt-decode';

export const ViewChangeOwnership = () => {
  const url = "https://toolbelt.ctcgroup.com.au:2908/HO/ChangeOwnership";
  const [items, setItems] = useState();
  const [isLoaded, setIsLoaded] = useState();
  const [error, setError] = useState();

  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');

  useEffect(() => {
        refreshToken();
  });

  useEffect(() => {
    fetch(url)
    .then((response) => {
      if(!response.ok) {
        throw new Error(`This is an HTTP error: The status is ${response.status}`);
      }
      return response.json();
    })
    .then((result) => {
      setItems(result);
      setError(null);
    })
    .catch((err) => {
      setError(err.message);
      setItems(null);
    })
    .finally(() => {
      setIsLoaded(true);
    });
  }, []);

  const refreshToken = async () => {
        try {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
              if(window.location.href === "/") {

              } else {
                  window.location.replace("/");
              }
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

  return(
    <div className="container-xl mb-3">
      <h1 className="inlinetitle">Change of Ownership</h1><div className="editButton" id="editButton"><a className="nav-link" id="addLink" href="/AddChangeOwnership">Add</a></div>
      {!isLoaded && <div>Loading... Please wait</div>}
      {error && (<div>{`There is a problem fetching store data - ${error}`}</div>)}
      <div className="table-responsive-sm">
      <table className="table table-striped table-borderless my-2">
        <thead>
          <tr>
            <th className="tablecentred" scope="col">Store ID</th><th className="tablecentred" scope="col">Store Name</th><th className="tablecentred" scope="col">Old BATA CustNo</th><th className="tablecentred" scope="col">New BATA CustNo</th><th className="tablecentred" scope="col">Old ITA CustNo</th><th className="tablecentred" scope="col">New ITA CustNo</th><th className="tablecentred" scope="col">Old PML CustNo</th><th className="tablecentred" scope="col">New PML CustNo</th><th className="tablecentred" scope="col">Start Date</th><th  className="tablecentred" scope="col">BDM</th>
          </tr>
        </thead>
        <tbody>
        {items &&
          items.map(({ StoreID, StoreName, OldBATACustNo, NewBATACustNo, OldITACustNo, NewITACustNo, OldPMLCustNo, NewPMLCustNo, startDate, bdm}) => (
            <tr key={StoreID}>
              <td className="tablecentred" data-label="Store ID">{StoreID}</td><td className="tablecentred" data-label="Store Name">{StoreName}</td><td className="tablecentred" data-label="Old BATA CustNo">{OldBATACustNo}</td><td className="tablecentred" data-label="New BATA CustNo">{NewBATACustNo}</td><td className="tablecentred" data-label="OldITACustNo">{OldITACustNo}</td><td className="tablecentred" data-label="New ITA CustNo">{NewITACustNo}</td><td className="tablecentred" data-label="Old PML CustNo">{OldPMLCustNo}</td><td className="tablecentred" data-label="New PML CustNo">{NewPMLCustNo}</td><td className="tablecentred" data-label="Start Date">{startDate}</td><td className="tablecentred" data-label="BDM">{bdm}</td>
            </tr>
        ))}
        </tbody>
      </table>
      </div>
    </div>
  );
}
