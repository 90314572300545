import React, { useState, useEffect, lazy, Suspense } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

const MarketShare = lazy(() => import("../components/dashboard/MarketShare"));
const MostPopBrand = lazy(() => import("../components/dashboard/MostPopBrands"));
const CustomerCount = lazy(() => import("../components/dashboard/CustomerCount"));
const Terms = lazy(() => import("../components/dashboard/Terms"));
const Sales = lazy(() => import("../components/dashboard/Sales"));

const ChangeOwnership = lazy(() => import("../components/dashboard/ChangeOwnership"));
const TopStore = lazy(() => import("../components/dashboard/TopStore"));

export const Dashboard = () => {

  const [username, setUsername] = useState('');
  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');
  const [storeid, setStoreid] = useState('');

  useEffect(() => {
        refreshToken();
  }, []);

  const refreshToken = async () => {
        try {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUsername(decoded.name);
            setStoreid(decoded.storeid);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                window.location.replace("/");
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('https://toolbelt.ctcgroup.com.au:5000/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUsername(decoded.name);
            setStoreid(decoded.storeid);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

  const d = new Date();
  let name = month[d.getMonth()];

  const renderLoader = () => <p>Loading</p>;

  return(
    <Suspense fallback={renderLoader()}>
    <div className="container-xl">
      <h1 className="inlinetitle">Your {name} at a Glance</h1>
      <div className="row">
        <div className="col-12 col-md-8">
        {storeid &&
          <MarketShare storeID={storeid}/>
        }
        </div>
        {storeid &&
        <div className="col-12 col-md-4">
          <MostPopBrand storeID={storeid}/>
          <CustomerCount storeID={storeid}/>
          {storeid !== 'HO' &&
            <Terms storeID={storeid}/>
          }
          {storeid === "HO" &&
            <ChangeOwnership rep={username} />
          }
          {storeid === "HO" &&
            <TopStore />
          }
        </div>
        }
      </div>
      <div className="row">
        <div className="col-12 col-md-12">
        {storeid &&
          <Sales storeID={storeid}/>
        }
          <p id="disclaimer">Please note: Data can be up to 30 minutes delayed</p>
        </div>
      </div>
    </div>
    </Suspense>
  );
}
